import React from "react";
import ResponsiveMedia from 'utils/responsive_media';
import PromotionBanner from "../../organisms/banner/promotionBanner"
import ServiceBanner from "../../organisms/banner/serviceBanner"
import OverlapImageTitle from "../parts/imageTitle/overlapImageTitle";
import Banner from "./banner";
import FeaturesCard from "./card/featuresCard";
import GuideCard from "./card/guideCard";
import APIFaq from "./faq";
import APIFeatures from "./features";
import Merits from "./merits";
import OpenLink from 'utils/open_link';

const API = ({title, featuresCard, features, banner, apiGuide, merits, apiFaq, apiFaqData, promotion}) => {
    const { isMobile } = ResponsiveMedia();

    const handlePromotionBanner = () => {
        OpenLink('사용자문의', 'API 도입 문의', '채널톡')
    }

    return (
        <>
        <OverlapImageTitle data={title} bgColor={"#1e1e1e"} padding={isMobile === 'true'? '' : '0 0 87px'} />
        <FeaturesCard data={featuresCard} />
        <APIFeatures data={features} />
        <Banner data={banner} />
        <GuideCard data={apiGuide} />
        <Merits data={merits} />
        <APIFaq title={apiFaq} data={apiFaqData} />
        <PromotionBanner 
                title={promotion.title} 
                subtitle={promotion.subtitle}
                buttonCount={1}
                leftTitle={"API 도입 문의"}
                leftButtonEvent={handlePromotionBanner}/>
        <ServiceBanner/>
        </>
    )
}

export default API;