import React from "react";
import ResponsiveMedia from 'utils/responsive_media';

import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import Card from "./card";

const GuideCard = ({data}) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <Container
            padding={isMobile === 'true'? '60px 20px' : '140px 40px'}
            height={'auto'}
            bgColor={'#ffffff'}>
                <Box>
                    <div style={{width: '100%'}}>
                    <Box
                        padding={'0 40px'}
                        flexDirection={"column"}
                        justifycontent={"center"}
                        alignitems={"center"}
                        gap={"38px"}>
                        <TextBold
                            type={isMobile === 'true'? '26' : '36'}
                            color={"#1e1e1e"}
                            textAlign={"center"}>
                            {data.title}</TextBold>
                        <TextNormal
                            type={isMobile === 'true'? '14' : '20'}
                            color={"#1e1e1e"}
                            textAlign={"center"}>
                            {isMobile? data.desc_mobile : data.desc}</TextNormal>
                    </Box>
                    <Box>
                        <Card data={data.items}/>
                    </Box>
                    </div>
                </Box>
        </Container>
    )
}

export default GuideCard;