import styled from "styled-components";

export const BannerContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 58px;
`;

export const BannerBox = styled.div`
    width: ${(props) => props.width};
    height: 100%;
    max-height: 180px;
    background-color: #ffffff;
    padding: 50px 80px 50px 20px;
    border-radius: 10px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 3;
`;

export const ValueBox = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 18px;
`;

export const ValueText = styled.div`
    font-size: 58px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.72;
    letter-spacing: -0.58px;
    text-align: center;
`;

export const DescBox = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`;

export const DescTypeText = styled.div`
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: -0.2px;
    text-align: left;
`;

export const DescText = styled.div`
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.72px;
    text-align: left;
    color: #1e1e1e;
`;
