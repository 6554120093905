import React from "react";
import ResponsiveMedia from 'utils/responsive_media';
import Image from "components/image/image";

import { CardTable, CardTableRow, CardTableCol, CardTitle, CardDesc, CardIconWrapper } from "./style";

const Card = ({data}) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <CardTable>
            <CardTableRow xs={isMobile === 'true'? "2" : "4"}>
            {data.map((item, index) => {
                return (
                    <CardTableCol key={index} mobile={isMobile}>
                        <CardIconWrapper>
                            <Image filename={item.img} alt={item.title}/>
                        </CardIconWrapper>
                        <CardTitle fontSize={isMobile === 'true'? '20px':'24px'}>{item.title}</CardTitle>
                        <CardDesc fontSize={isMobile === 'true'? '16px':'18px'} >{item.desc}</CardDesc>
                    </CardTableCol>
                )
            })}
            </CardTableRow>
        </CardTable>
    )
}

export default Card;