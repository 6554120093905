import React from "react";
import ResponsiveMedia from "utils/responsive_media";
import Image from "components/image/image";
import { Container, Box, BackgroundImageWrapper } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import { SquareButton } from "components/button";
import { showChannelTalk } from "hooks/useChannelIO";
import { ColoredBox } from "./style";
import { PROMOTION_RIGHT_TITLE } from "constants/data/promotion";

const OverlapImageTitle = ({ data, bgColor, padding }) => {
    const { isMobile } = ResponsiveMedia();
    const height = isMobile ? "auto" : "546px";
    return (
        <Container height={"auto"} alignitems={"center"} justifycontent={"center"} padding={padding} flexDirection={"column"}>
            <ColoredBox height={isMobile === "true" ? "410px" : "740px"} bgColor={bgColor || "#faf7ef"} />
            <Box flexDirection={"column"} padding={isMobile === "true" ? "60px 0" : "120px 0 80px"}>
                <TextBold type={isMobile === "true" ? "26" : "42"} textAlign={"center"} color={"#ffffff"} margin={isMobile === "true" ? "0 0 15px" : "0 0 16px"} whiteSpace={isMobile === "true" ? "pre-line" : "none"}>
                    {data.title}
                </TextBold>
                <TextNormal type={isMobile === "true" ? "16" : "20"} textAlign={"center"} color={"#ffffff"} margin={isMobile === "true" ? "0 0 30px" : "0 0 24px"}>
                    {data.desc}
                </TextNormal>
                <SquareButton
                    width={isMobile === "true" ? "180px" : "210px"}
                    fontSize={isMobile === "true" ? "16px" : "18px"}
                    color={"#fff"}
                    hColor={"#fff"}
                    aColor={"#fff"}
                    bgColor={"#cd001a"}
                    hBgColor={"#cd001a"}
                    aBgColor={"#a20b0b"}
                    text={PROMOTION_RIGHT_TITLE}
                    onClickEvent={() => showChannelTalk()}
                />
            </Box>
            <BackgroundImageWrapper style={{ position: "relative" }} maxWidth={isMobile ? "100%" : "968px"}>
                <Image filename={isMobile === "true" ? data.img_mobile : data.img} alt={data.title} style={{ height: height }} />
            </BackgroundImageWrapper>
        </Container>
    );
};

export default OverlapImageTitle;
