import React, { useState } from "react";
import ResponsiveMedia from 'utils/responsive_media';
import Image from "components/image/image";

import { CardTable, CardTableRow, CardTableCol, CardTitle, CardDesc, CardIconWrapper, CardIndex, CardDivider } from "./style";

const Card = ({data}) => {
    const { isMobile } = ResponsiveMedia();

    const [mouseOver, setMouseOver] = useState(-1);

    return (
        <CardTable>
            <CardTableRow xs={isMobile === 'true'? "2" : "4"}>
            <CardDivider isMobile={isMobile}/>
            {data.map((item, index) => {
                return (
                    <CardTableCol key={index} mobile={isMobile} onMouseOver={()=>setMouseOver(index)} onMouseOut={()=>setMouseOver(-1)}>
                        <CardIconWrapper>
                            <Image filename={mouseOver === index? item.icon_act:item.icon} alt={item.title}/>
                        </CardIconWrapper>
                        <CardIndex active={mouseOver === index}>{'STEP ' + (index + 1)}</CardIndex>
                        <CardTitle fontSize={isMobile === 'true'? '20px':'27px'}>{item.title}</CardTitle>
                        <CardDesc fontSize={isMobile === 'true'? '16px':'18px'} >{item.desc}</CardDesc>
                    </CardTableCol>
                )
            })}
            </CardTableRow>
        </CardTable>
    )
}

export default Card;