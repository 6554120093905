import * as React from "react";
import Seo from "components/seo";
import { graphql } from "gatsby";
import LayoutBasic from "../../templates/layoutBasic";
import API from "organisms/api";
import { API_META } from "../../constants/data/meta_data";
import { API_TITLE, API_FEATURES_CARD, API_FEATURES, API_BANNER, API_GUIDE_CARD, API_MERITS, API_FAQ } from "constants/data/api";
import { API_PROMOTION_BANNER } from "../../constants/data/api";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const APIPage = ({ data }) => {
    const { i18n } = useTranslation();

    const planFaqData = useMemo(() => {
        if (!data[`pricing_faq_data${`_${i18n.language}`}`]) return [];

        return data[`pricing_faq_data${`_${i18n.language}`}`].edges;
    }, [data, i18n]);

    return (
        <LayoutBasic>
            <Seo title={API_META.title} description={API_META.description} canonical={API_META.canonical} kakaoKeyword={API_META.kakao} />
            <API title={API_TITLE} featuresCard={API_FEATURES_CARD} features={API_FEATURES} banner={API_BANNER} apiGuide={API_GUIDE_CARD} merits={API_MERITS} apiFaq={API_FAQ} apiFaqData={planFaqData} promotion={API_PROMOTION_BANNER} />
        </LayoutBasic>
    );
};

export default APIPage;

export const PricingFaqData = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        pricing_faq_data_ko: allPricingFaqDataKoCsv {
            edges {
                node {
                    id
                    category
                    title
                    desc
                }
            }
        }

        pricing_faq_data_en: allPricingFaqDataEnCsv {
            edges {
                node {
                    id
                    category
                    title
                    desc
                }
            }
        }
    }
`;
