import React from "react";
import ResponsiveMedia from 'utils/responsive_media';

import { Container, Box } from "components/style/layoutStyle";
import { TextBold } from "components/style/textStyle";
import DescriptionWidhImage from "../../parts/description/descriptionWithImage";

const APIFeatures = ({data}) => {
    const { isMobile } = ResponsiveMedia();
    const noticeData = data.items.notice;
    const dataloadData = data.items.dataload;
    const graphData = data.items.graph;
    return (
        <Container
            flexDirection={"column"}
            height={'auto'}
            bgColor={'#ffffff'}>
                <Box
                    padding={isMobile? '20px 40px 20px':'100px 40px 80px'}
                    justifycontent={"center"}
                    alignitems={"center"}>
                    <TextBold
                        type={isMobile === 'true'? '26' : '36'}
                        textAlign={"center"}>
                        {data.title}</TextBold>
                </Box>
                <DescriptionWidhImage data={noticeData} imgWidth={'748px'} bgColor={'#f9f9f9'} padding={"120px 0"} reverse/>
                <DescriptionWidhImage data={dataloadData} imgWidth={'748px'} padding={"120px 0"}/>
                <DescriptionWidhImage data={graphData} imgWidth={'748px'} bgColor={'#f9f9f9'} padding={"120px 0"} reverse/>
        </Container>
    )
}  

export default APIFeatures;