import React from "react";
import ResponsiveMedia from 'utils/responsive_media';
import Image from "components/image/image";
import { showChannelTalk } from "hooks/useChannelIO";
import { TextBold, TextNormal } from "components/style/textStyle";
import { Container, Box } from "components/style/layoutStyle";
import { IC_ARROW_RIGHT_LONG } from "../../../constants/media/icon";
import { Button, ButtonTitle } from "./styles";

const Banner = ({data}) => {
    const { isMobile } = ResponsiveMedia();

    const openChannelTalk = () => {
        showChannelTalk();
    }

    return (
        <Container
            height={'240px'}
            bgColor={'#1e1e1e'}>
                <Box
                    flexDirection={isMobile?"column":"row"}
                    justifycontent={isMobile?"center":"space-between"}
                    alignitems={"center"}
                    maxWidth={"1216px"}>
                    <Box
                        flexDirection={"column"}
                        justifycontent={"center"}
                        alignitems={isMobile?"center":"flex-start"}
                        height={"auto"}
                        gap={"11px"}>
                            <TextBold
                                type={isMobile === 'true'? '22' : '32'}
                                color={"#ffffff"}
                                textAlign={isMobile?"center":"left"}>{data.title}</TextBold>
                            <TextNormal
                                type={isMobile === 'true'? '':''}
                                color={"#ffffff"}
                                textAlign={isMobile?"center":"left"}>{data.desc}</TextNormal>
                    </Box>
                    <Button onClick={()=>openChannelTalk()}>
                        <ButtonTitle>{data.link.title}</ButtonTitle>
                        <Image filename={IC_ARROW_RIGHT_LONG} alt={data.link.title} style={{width: "40px", height: "18px"}}/>
                    </Button>
                </Box>
        </Container>
    )
}

export default Banner;