import styled from "styled-components";

export const Button = styled.div`
    width: 280px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    border-radius: 4px;
    cursor: pointer;
`;

export const ButtonTitle = styled.div`
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.54px;
    text-align: center;
    color: #1e1e1e;
`;
