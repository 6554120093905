import React from "react";
import ResponsiveMedia from 'utils/responsive_media';

import { Container, Box } from "components/style/layoutStyle";
import { TextBold } from "components/style/textStyle";
import Card from "./card";

const FeaturesCard = ({data}) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <Container
            padding={isMobile === 'true'? '60px 20px' : '80px 40px'}
            height={'auto'}
            bgColor={'#ffffff'}>
                <Box>
                    <div style={{width: '100%'}}>
                    <Box
                        padding={'0 40px'}
                        justifycontent={"center"}
                        alignitems={"center"}
                        >
                        <TextBold
                            type={isMobile === 'true'? '26' : '36'}
                            textAlign={"center"}>
                            {data.title}</TextBold>
                    </Box>
                    <Box>
                        <Card data={data.items}/>
                    </Box>
                    </div>
                </Box>
        </Container>
    )
}

export default FeaturesCard;