// import { IC_API_CARD_ADMIN, IC_API_CARD_CONTRACT, IC_API_CARD_NOTICE, IC_API_COMP_CHAT, IC_API_COMP_CHAT_ACTIVE, IC_API_COMP_MANAGE, IC_API_COMP_MANAGE_ACTIVE, IC_API_COMP_SETTING, IC_API_COMP_SETTING_ACTIVE, IC_API_MERIT_ARROW_BLUE, IC_API_MERIT_ARROW_GREEN, IC_API_MERIT_ARROW_PINK } from "../media/icon";
// import { IMG_API_FEATURE_DATALOAD, IMG_API_FEATURE_GRAPH, IMG_API_FEATURE_NOTICE, IMG_TOP_API } from "../media/images";

// export const API_TITLE = {
//     img: IMG_TOP_API,
//     img_mobile: IMG_TOP_API,
//     title: '단 하나의 기업만을 위한\n맞춤형 API 연동 서비스',
//     desc: '도뉴의 API는 기업마다 다른 니즈를 해결해드립니다.',
// }

// export const API_BANNER = {
//     title: "기업의 니즈에 맞게 선택하세요!",
//     desc: "도뉴 개발자 센터에서 API 가이드를 확인하고 사용해보세요!",
//     link: {
//         type: 'link',
//         title: 'API Developer',
//         url: '채널톡',
//     },
// }

// export const API_FEATURES_CARD = {
//     title: "필요한 기능만 골라\n나만의 전자계약을 구축하세요.",
//     items: [
//         {
//             img: IC_API_CARD_CONTRACT,
//             title: '계약 방식',
//             desc: '전문적인 API 지원을 통해\n산업별 다양한 계약 방식을\n전자계약으로 진행할 수 있습니다.'
//         },
//         {
//             img: IC_API_CARD_NOTICE,
//             title: '알림 발송',
//             desc: '필요한 시점에 직접 작성한 내용으로\n계약의 모든 과정을 실시간 \n알림 받을 수 있습니다.'
//         },
//         {
//             img: IC_API_CARD_ADMIN,
//             title: '통합 관리',
//             desc: '어드민페이지를 통해\n자사 API의 사용 통계/로그기록을\n통합 관리 및 조회할 수 있습니다.'
//         },
//     ]
// }

// export const API_FEATURE_NOTICE = {
//     img: IMG_API_FEATURE_NOTICE,
//     title: "카카오톡, 문자 등\n기업 맞춤형 커스텀 알림",
//     desc: "기존에 사용중인 알림 방식과 함께\n원하는 구간에 실시간으로\n계약 알림을 받으실 수 있습니다.",
// }

// export const API_FEATURE_DATALOAD = {
//     img: IMG_API_FEATURE_DATALOAD,
//     title: "원하는 데이터를\n간편하게 불러오기",
//     desc: "필요한 데이터를 조회하여\n계약을 진행하는데 필요한 정보를\n간편하게 불러올 수 있습니다.",
// }

// export const API_FEATURE_GRAPH = {
//     img: IMG_API_FEATURE_GRAPH,
//     title: "관리자 페이지를 통해\n한눈에 보는 API 사용 통계",
//     desc: "어드민 페이지를 통해\nAPI 사용 통계 및 로그기록을\n일괄 조회 및 관리할 수 있습니다.",
// }

// export const API_FEATURES = {
//     title: "도뉴 API에서 만날 수 있는\n다양한 기능",
//     items: {
//         notice: API_FEATURE_NOTICE,
//         dataload: API_FEATURE_DATALOAD,
//         graph: API_FEATURE_GRAPH,
//     }
// }

// export const API_GUIDE_CARD = {
//     title: "연동부터 관리까지.\n간편하고 빠르게.",
//     desc: "API를 쉽고 빠르게 구축할 수 있도록 상세한 가이드와 레퍼런스를 제공합니다.",
//     desc_mobile: "API를 쉽고 빠르게 구축할 수 있도록\n상세한 가이드와 레퍼런스를 제공합니다.",
//     items: [
//         {
//             title: '도입 문의',
//             desc: '필요한 API 기능과 가격을\n상담받아보세요.',
//             icon: IC_API_COMP_CHAT,
//             icon_act: IC_API_COMP_CHAT_ACTIVE,
//         },
//         {
//             title: '연동 및 구축',
//             desc: '전문 개발진의 개발 지원을 통해 \n간편한 API 연동을 진행하세요.',
//             icon: IC_API_COMP_SETTING,
//             icon_act: IC_API_COMP_SETTING_ACTIVE,
//         },
//         {
//             title: '유지 및 관리',
//             desc: '구축 완료 후 사용된 모든 API를\n대시보드 통해 관리하세요.',
//             icon: IC_API_COMP_MANAGE,
//             icon_act: IC_API_COMP_MANAGE_ACTIVE,
//         },
//     ]
// }

// export const API_MERITS = {
//     title: "이 외에도 도뉴 API를 선택하는 이유",
//     items: [
//         {
//             title: "타사 대비 합리적인 비용의 API 연동",
//             type: "Economical",
//             type_color: "#6591da",
//             value: "48%",
//             value_color: "#5a91e0",
//             direction: "down",
//             icon: IC_API_MERIT_ARROW_BLUE,
//         },
//         {
//             title: "도뉴의 개발 지원을 통한 개발 시간 감소",
//             type: "Time-saving",
//             type_color: "#50b43e",
//             value: "6.3일",
//             value_color: "#50b43e",
//             direction: "down",
//             icon: IC_API_MERIT_ARROW_GREEN,
//         },
//         {
//             title: "필요 기능만 담은 효율적인 전자계약 구축",
//             type: "Efficient",
//             type_color: "#e3627f",
//             value: "54%",
//             value_color: "#e3627f",
//             direction: "up",
//             icon: IC_API_MERIT_ARROW_PINK,
//         },
//     ]
// }

// export const API_FAQ = {
//     title: '도뉴 API\n자주 묻는 질문',
//     link: {
//         type: 'link',
//         title: '고객지원 바로가기',
//         url: '/support/faq',
//     }
// }

// export const API_PROMOTION_BANNER = {
//     title : "기업을 위한 맞춤형\n전자계약 API를 경험하세요.",
//     subtitle: "지금 바로 도입 문의를 통해\nAPI 무료 견적 상담을 받아보세요.",
// }

import {
    IC_API_CARD_ADMIN,
    IC_API_CARD_CONTRACT,
    IC_API_CARD_NOTICE,
    IC_API_COMP_CHAT,
    IC_API_COMP_CHAT_ACTIVE,
    IC_API_COMP_MANAGE,
    IC_API_COMP_MANAGE_ACTIVE,
    IC_API_COMP_SETTING,
    IC_API_COMP_SETTING_ACTIVE,
    IC_API_MERIT_ARROW_BLUE,
    IC_API_MERIT_ARROW_GREEN,
    IC_API_MERIT_ARROW_PINK
} from "../media/icon";
import { IMG_API_FEATURE_DATALOAD, IMG_API_FEATURE_GRAPH, IMG_API_FEATURE_NOTICE, IMG_TOP_API } from "../media/images";

export const API_TITLE = {
    img: IMG_TOP_API,
    img_mobile: IMG_TOP_API,
    title: "API_TITLE_TEXT",
    desc: "API_DESC_TEXT"
};

export const API_BANNER = {
    title: "API_BANNER_TITLE_TEXT",
    desc: "API_BANNER_DESC_TEXT",
    link: {
        type: "link",
        title: "API_BANNER_LINK_TITLE_TEXT",
        url: "채널톡"
    }
};

export const API_FEATURES_CARD = {
    title: "API_FEATURES_CARD_TITLE",
    items: [
        {
            img: IC_API_CARD_CONTRACT,
            title: "API_FEATURES_CARD_ITEM_1_TITLE",
            desc: "API_FEATURES_CARD_ITEM_1_DESC"
        },
        {
            img: IC_API_CARD_NOTICE,
            title: "API_FEATURES_CARD_ITEM_2_TITLE",
            desc: "API_FEATURES_CARD_ITEM_2_DESC"
        },
        {
            img: IC_API_CARD_ADMIN,
            title: "API_FEATURES_CARD_ITEM_3_TITLE",
            desc: "API_FEATURES_CARD_ITEM_3_DESC"
        }
    ]
};

export const API_FEATURE_NOTICE = {
    img: IMG_API_FEATURE_NOTICE,
    title: "API_FEATURE_NOTICE_TITLE_TEXT",
    desc: "API_FEATURE_NOTICE_DESC_TEXT"
};

export const API_FEATURE_DATALOAD = {
    img: IMG_API_FEATURE_DATALOAD,
    title: "API_FEATURE_DATALOAD_TITLE",
    desc: "API_FEATURE_DATALOAD_DESC"
};

export const API_FEATURE_GRAPH = {
    img: IMG_API_FEATURE_GRAPH,
    title: "API_FEATURE_GRAPH_TITLE",
    desc: "API_FEATURE_GRAPH_DESC"
};

export const API_FEATURES = {
    title: "API_FEATURES_TITLE",
    items: {
        notice: "API_FEATURE_NOTICE",
        dataload: "API_FEATURE_DATALOAD",
        graph: "API_FEATURE_GRAPH"
    }
};

export const API_GUIDE_CARD = {
    title: "API_GUIDE_CARD_TITLE",
    desc: "API_GUIDE_CARD_DESC",
    desc_mobile: "API_GUIDE_CARD_DESC_MOBILE",
    items: [
        {
            title: "API_GUIDE_CARD_ITEM_1_TITLE",
            desc: "API_GUIDE_CARD_ITEM_1_DESC",
            icon: IC_API_COMP_CHAT,
            icon_act: IC_API_COMP_CHAT_ACTIVE
        },
        {
            title: "API_GUIDE_CARD_ITEM_2_TITLE",
            desc: "API_GUIDE_CARD_ITEM_2_DESC",
            icon: IC_API_COMP_SETTING,
            icon_act: IC_API_COMP_SETTING_ACTIVE
        },
        {
            title: "API_GUIDE_CARD_ITEM_3_TITLE",
            desc: "API_GUIDE_CARD_ITEM_3_DESC",
            icon: IC_API_COMP_MANAGE,
            icon_act: IC_API_COMP_MANAGE_ACTIVE
        }
    ]
};

export const API_MERITS = {
    title: "Reasons to Choose Donu API",
    items: [
        {
            title: "API_MERITS_ITEM_1_TITLE",
            type: "Economical",
            type_color: "#6591da",
            value: "48%",
            value_color: "#5a91e0",
            direction: "down",
            icon: IC_API_MERIT_ARROW_BLUE
        },
        {
            title: "API_MERITS_ITEM_2_TITLE",
            type: "Time-saving",
            type_color: "#50b43e",
            value: "6.3 days",
            value_color: "#50b43e",
            direction: "down",
            icon: IC_API_MERIT_ARROW_GREEN
        },
        {
            title: "API_MERITS_ITEM_3_TITLE",
            type: "Efficient",
            type_color: "#e3627f",
            value: "54%",
            value_color: "#e3627f",
            direction: "up",
            icon: IC_API_MERIT_ARROW_PINK
        }
    ]
};

export const API_FAQ = {
    title: "API_FAQ_TITLE",
    link: {
        type: "link",
        title: "API_FAQ_LINK_TITLE",
        url: "/support/faq"
    }
};

export const API_PROMOTION_BANNER = {
    title: "API_PROMOTION_BANNER_TITLE",
    subtitle: "API_PROMOTION_BANNER_SUBTITLE"
};
