import React from "react";
import ResponsiveMedia from 'utils/responsive_media';
import Image from "components/image/image";
import { Container, Box } from "components/style/layoutStyle";
import { TextBold, TextNormal } from "components/style/textStyle";
import { BannerBox, BannerContainer, DescBox, DescText, DescTypeText, ValueBox, ValueText } from "./styles";

const Merits = ({data}) => {
    const { isMobile } = ResponsiveMedia();
    return (
        <Container
            bgColor={"#f8f8f8"}
            flexDirection={"column"}
            gap={"78px"}
            padding={"154px 0"}>
                <TextBold
                    type={"36"}
                    color={"#1e1e1e"}>{data.title}</TextBold>
                <BannerContainer>
                    {data.items.map((item, index) => {
                        return (
                            <BannerBox 
                                width={isMobile? "100%":"904px"}
                                key={index}>
                                <ValueBox
                                    data-sal={item.direction === "down"?"slide-down":"slide-up"}
                                    data-sal-duration="1500"
                                    data-sal-easing="ease">
                                    <Image filename={item.icon} alt={item.title} style={{width: "54px", height: "69px"}}/>
                                    <ValueText style={{color: item.value_color}}>{item.value}</ValueText>
                                </ValueBox>
                                <DescBox>
                                    <DescTypeText style={{color: item.type_color}}>{item.type}</DescTypeText>
                                    <DescText>{item.title}</DescText>
                                </DescBox>
                            </BannerBox>
                        )
                    })}
                </BannerContainer>
        </Container>
    )
}

export default Merits;