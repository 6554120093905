import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

export const CardTable = styled(Container)`
    width: 100%;
    margin: 80px 0 0;
    padding: 0 !important;
    max-width: 100% !important;
`;

export const CardTableRow = styled(Row)`
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 46px;
`;

export const CardTableCol = styled(Col)`
    ${(props) =>
        props.mobile
            ? `
        width: 372px !important;
        height: 356px !important;
        padding: 48px 24px 52px;
        > div {
            &:nth-child(1) {
                margin: 0 0 24px;
            }
            
            &:nth-child(2) {
                margin: 0 0 16px;
            }
        }
    `
            : `
        width: 348px !important;
        height: 381px !important;
        padding: 40px 40px 44px;
        > div {
            &:nth-child(1) {
                margin: 0 0 32px;
            }
            
            &:nth-child(2) {
                margin: 0 0 24px;
            }
        }
    `};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    border-radius: 12px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;

    &: hover {
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
    }
`;

export const CardIconWrapper = styled.div`
    width: 124px;
    height: 124px;
`;

export const CardTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.6px;
    text-align: center;
    color: #552c2c;
    white-space: pre-line;
`;

export const CardDesc = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: center;
    color: #1e1e1e;
    white-space: pre-line;
`;
