import styled from "styled-components";

export const FaqTableBox = styled.div`
    width: 100%;
    border-bottom: solid 1px #ddd;
`;

export const FaqTableWrapper = styled.div`
    width: 100%;
    transition: all 0.2s ease-in-out;
    border-top: ${(props) => props.borderTop};
    ${(props) =>
        props.mobile
            ? `
    border-top: ${props.open ? "solid 1px #1e1e1e;" : "solid 1px #ddd"};
`
            : `
    border-top: ${props.open ? "solid 1px #1e1e1e;" : "solid 1px #ddd"};
`}
    background-color: ${(props) => (props.open ? "#f8f8f8" : "#ffffff")};
`;

export const FaqTableRow = styled.div`
    width: 100%;
    height: auto;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    > * {
        border-bottom: unset !important;
    }
`;

export const FaqTableCell = styled.div`
    display: flex !important;
    align-items: flex-start !important;
    padding: 0px !important;
    border-bottom: none !important;
    ${(props) =>
        props.desc
            ? `
font-size: 16px !important;
`
            : `
font-size: 18px !important;
`}
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38 !important;
    letter-spacing: -0.48px !important;
    text-align: left !important;
    color: #000;
    margin: 20px;
    flex: ${(props) => props.flex};
`;

export const Bold = styled.p`
    font-weight: 500;
    display: flex;
    margin: 0 5px 0 0;
`;

export const FaqBox = styled.div`
    transition: all 0.2s ease-in-out;
    border-bottom: none;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: -0.42px;
    text-align: left;
    color: #1e1e1e;

    width: 100%;
    overflow: hidden;

    ${(props) =>
        props.open
            ? `
    max-height: 500px;
    ${
        props.mobile
            ? `
        padding: 0 20px 0;
    `
            : `
        padding: 12px 20px 0;
    `
    }
`
            : `
    padding: 0 20px;
    max-height: 0px;
`}
`;
