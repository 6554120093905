import styled from "styled-components";
import { Container, Row, Col } from "reactstrap";

export const CardTable = styled(Container)`
    width: 100%;
    margin: 80px 0 0;
    padding: 0 !important;
    max-width: 100% !important;
    position: relative;
`;

export const CardTableRow = styled(Row)`
    margin: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 46px;
`;

export const CardTableCol = styled(Col)`
    ${(props) =>
        props.mobile
            ? `
        width: 335px !important;
        height: 307px !important;
        padding: 48px 24px 52px;
    `
            : `
        width: 348px !important;
        height: 381px !important;
        padding: 40px 40px 44px;
    `};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #fff;
`;

export const CardIconWrapper = styled.div`
    width: 200px;
    height: 200px;
`;

export const CardIndex = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 60px;
    padding: 0 20px;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: -0.14px;
    text-align: center;
    ${(props) =>
        props.active
            ? `
        background-color: #552c2c;
        color: #ffffff;
    `
            : `
        background-color: #efefef;
        color: #999;
    `}
`;

export const CardTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: -0.6px;
    text-align: center;
    color: #1e1e1e;
    white-space: pre-line;
    padding: 16px 0 19px;
`;

export const CardDesc = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: ${(props) => props.fontSize};
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.48px;
    text-align: center;
    color: #1e1e1e;
    white-space: pre-line;
`;

export const CardDivider = styled.hr`
    width: 100%;
    min-width: 10000px;
    border: none;
    border-top: 3px dotted #b5b5b5;
    color: #fff;
    background-color: #fff;
    position: absolute;
    top: 115px;
    visibility: ${(props) => (props.isMobile ? `hidden` : `visible`)};
`;
